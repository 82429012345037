import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Hero, Grid } from "page_components/news-knowledge"

const NewsKnowledge = ({ data }) => {
  const title = "Aktualności/Baza wiedzy"

  const isBrowser = typeof window !== "undefined"
  const queryParameters = new URLSearchParams(
    isBrowser && window.location.search
  )
  const categoryParam = queryParameters.get("category")

  const [cat, setCat] = useState(categoryParam ? categoryParam : "aktualnosci")
  const [subCat, setSubCat] = useState(null)
  const [filteredData, setFilteredData] = useState(data?.allWpPost?.nodes)

  const newsKnowledgeData = {
    cat,
    setCat,
    subCat,
    setSubCat,
  }

  useEffect(() => {
    setFilteredData(
      data?.allWpPost?.nodes.filter(item =>
        item.categories.nodes.find(el => el.slug === cat)
      )
    )
  }, [cat, data?.allWpPost?.nodes])

  return (
    <Layout
      seo={{
        title: "Aktualności",
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Hero {...newsKnowledgeData} data={filteredData} />
      <Grid
        {...newsKnowledgeData}
        data={filteredData}
        categories={data?.allWpCategory?.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCategory {
      nodes {
        name
        slug
        wpParent {
          node {
            slug
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        excerpt
        date
        categories {
          nodes {
            uri
            name
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPost {
          investmentsRelation {
            ... on WpInvestment {
              id
              uri
              title
            }
          }
        }
      }
    }
  }
`

export default NewsKnowledge
